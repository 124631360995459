import React, {useState} from "react";

export const StateContext = React.createContext();

const StateProvider = ({children}) => {
    const [currentDialog, setCurrentDialog] = useState('');
    const [currentDialogState, setCurrentDialogState] = useState('');
    const [dialogParams, setDialogParams] = useState('');

    return (
        <StateContext.Provider value={{
            currentDialog,
            setCurrentDialog,
            currentDialogState,
            setCurrentDialogState,
            dialogParams,
            setDialogParams
        }}>
            {children}
        </StateContext.Provider>
    )
}

const StateWrapper = ({element}) => {
    return <StateProvider>{element}</StateProvider>
}

export default StateWrapper;

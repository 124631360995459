import StateWrapper from "./src/provider/provider";

export const onRouteUpdate = ({location, prevLocation}) => {
	if (location?.pathname !== prevLocation?.pathname) {
		document.documentElement.classList.remove('is-overlay');
		// window.scrollTo(0,0);
	}
}

export const wrapRootElement = StateWrapper
